<template>
    <v-container fluid class="px-8">
        <!-- 学生练习列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer mt-5"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
        >
            <template v-slot:item.name="{ item }">
                <div class="font-weight-medium" style="cursor: pointer" @click.stop="goto(item.cid, item.user_res_id, item.sub_type)">
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.avatar="{ item }">
                {{item.avatar}}
                <v-avatar color="white" size="36">
                    <v-img
                        :lazy-src="getAvatar(item.avatar)"
                        :src="getAvatar(item.avatar)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.sub_type="{ item }">
                <v-chip :color="getTypeColor(item.sub_type)" dark >
                    {{ item.sub_type }}
                </v-chip>
            </template>
            <template v-slot:item.operate="{ item }">
                <v-btn
                    class="mx-2 darken-2"
                    fab
                    outlined
                    dark
                    x-small
                    elevation="2"
                    color="orange"
                    @click.stop="goto(item.cid, item.user_res_id, item.sub_type)"
                >
                    <v-icon dark> mdi-arrow-right </v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <ConfirmDlg
            ref="delCourseConfirmDlg"
            title="删除学生信息"
            text="确认 删除 当前学生信息么？"
            keyword=" 删除 "
            @confirm="delStudentConfirm"
        />

    </v-container>
</template>

<script>
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import { getStudentExerciseList } from "@/api/admin";
import { getAvatar, getTypeFirstLetter } from "@/utils/util";
import { getTypeColor, gotoSection } from '@/utils/common';

export default {
    name: "StudentExercise",
    data() {
        return {
            uid: "",
            headers: [
                { text: "课程名称", value: "course_name", width: 200, align: "start" },
                { text: "小节", value: "section_name", width: 200, align: "center", sortable: false },
                { text: "类型", value: "sub_type", align: "center", sortable: false },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 130},
            ],
            datas: [],
        };
    },
    activated() {
        if (this.$route.query.id) {
            this.uid = this.$route.query.id;
        }
        this.loadData();
    },
    methods: {
        getAvatar,
        getTypeColor,
        getTypeFirstLetter,
        gotoSection,
        loadData() {
            getStudentExerciseList(this.uid).then((response) => {
                // console.log(response.data);
                this.datas = response.data;

            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(cid, sid, subType) {
            this.gotoSection(cid, sid, subType, 'editor', 'push')
        },
        openEditCourseDlg(course = null) {
            this.$refs.editCourseDlg.openDlg(course);
        },
        openConfirmDlg(id) {
            this.$refs.delCourseConfirmDlg.openDlg(id);
        },
        delStudentConfirm() {
            let pass;
        },
    },
    components: {
        ConfirmDlg,
    }
};
</script>

<style lang="scss" scoped>
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.avatar {
    height: 45px;
    width: 45px;
    img {
        height: 100%;
        width: 100%;
    }
}
</style>